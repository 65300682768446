@import "src/styles/variables.module.scss";

.main {
  background: $sacri-white;
  box-sizing: border-box;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

.section {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 375px;
  padding: 0;
  width: 100%;
}

.logo {
  display: flex;
  justify-content: center;
  padding: 28px 0;
}