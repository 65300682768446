@import "src/styles/variables.module";

.flex {
  &__row {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    &_align {
      &_start {
        align-items: flex-start;
      }
      &_center {
        align-items: center;
      }
      &_end {
        align-items: flex-end;
      }
    }
    &_wrap {
      flex-flow: row wrap;
    }
    &_nowrap {
      flex-flow: row nowrap;
    }
    &_start {
      @extend .flex__row;
      justify-content: flex-start;
    }
    &_between {
      @extend .flex__row;
      justify-content: space-between;
    }
    &_end {
      @extend .flex__row;
      justify-content: flex-end;
    }
    &_center {
      @extend .flex__row;
      justify-content: center;
    }
    &_around {
      @extend .flex__row;
      justify-content: space-around;
    }

    &__item {
      &_two {
        > * {
          width: 50%;
        }
      }

      &_three {
        > * {
          width: 33.3333%;
        }
      }
    }
  }
  &__column {
    display: flex;
    flex-flow: column;
    justify-content: center;
    &_start {
      @extend .flex__column;
      align-items: flex-start;
    }
    &_end {
      @extend .flex__column;
      align-items: flex-end;
    }
    &_center {
      @extend .flex__column;
      align-items: center;
    }
  }
}