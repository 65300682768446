@import "src/styles/variables.module";

.link {
  color: $black;
  cursor: pointer;
  display: block;
  text-decoration: underline;
  &_none {
    text-decoration: none;
  }
}

.lineHeight {
  &__none {
    line-height: 1;
  }
}

.nowrap {
  white-space: nowrap;
}

.ellipsis {
  &_two {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.align {
  &_left {
    text-align: left;
  }
  &_center {
    text-align: center;
  }
  &_right {
    text-align: right;
  }
}

.color {
  &_blue {
    &_light {
      color: $sacri-blue-light
    }
    &_main {
      color: $sacri-blue
    }
    &_dark {
      color: $sacri-blue-dark
    }
  }
  &_orange {
    &_main {
      color: $sacri-orange
    }
  }
  &_red {
    &_light {
      color: $sacri-red-light
    }
    &_main {
      color: $sacri-red
    }
    &_dark {
      color: $sacri-red-dark
    }
  }

  &_caution {
    color: $caution-red;
  }

  &_grey {
    &_01 {
      color: $grey01;
    }
    &_02 {
      color: $grey02;
    }
    &_03 {
      color: $grey03;
    }
    &_04 {
      color: $grey04;
    }
    &_05 {
      color: $grey05;
    }
  }

  &_black {
    &_sacri {
      color: $sacri-black;
    }
  }
}

.font {
  &_xxsmall {
    font-size: 10px;
  }
  &_xsmall {
    font-size: 11px;
  }
  &_small {
    font-size: 12px;
  }
  &_medium {
    font-size: 14px;
  }
  &_large {
    font-size: 18px;
  }
  &_xlarge {
    font-size: 22px;
  }
  &_xxlarge {
    font-size: 24px;
  }
  &_bold {
    font-weight: 700;
  }
}

.wordBreakAll {
  word-break: break-all;
}