$sacri-red-light: #FA705C;
$sacri-red: #C23F32;
$sacri-red-dark: #8B000B;
$sacri-orange-light: #EDB294;
$sacri-orange: #DB6529;
$sacri-gradation: radial-gradient(100% 100% at 0% 0%, #EE8023 0%, #C23F32 100%);

$sacri-blue-light: #6A9EAC;
$sacri-blue: #2A7589;
$sacri-blue-dark: #00495C;
$ios-blue: #007AFF;

$sacri-yellow-light: #FBF8D8;
$sacri-yellow: #EDDB3A;
$sacri-green: #279A40;
$sacri-white: #EEEEE3;
$sacri-white-dark: #989884;
$sacri-black: #231815;
$caution-red-light: #F3D9D6;
$caution-red: #FF0303;

$black: #000;
$grey01: #6A6A6A;
$grey02: #9A9A9A;
$grey03: #BABABA;
$grey04: #DADADA;
$grey05: #F6F7F8;
$white: #fff;
$modal-white: #FDFDFD;
$modal-black: #1A1A1A;
$ghost: #BABABA 70%;
$nav-bar: #1A1A1A;
$shadow: #E0E1E3;

$breakpoints: (
  'xs': 'screen and (min-width: 440px)',
  'sm': 'screen and (min-width: 600px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 1024px)',
  'xl': 'screen and (min-width: 1440px)',
) !default;

// 初期値はlg(tablet screen size)
@mixin mq($breakpoint: lg) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// プレイスホルダーの色指定
@mixin placeholder($color) {
  &::placeholder {
    color: $color !important;
  }

  // IE
  &:-ms-input-placeholder {
    color: $color !important;
  }

  // Edge
  &::-ms-input-placeholder {
    color: $color !important;
  }
}
