@import "src/styles/variables.module";

.description {
  color: $black;
  font-size: 16px;
  font-weight: 400;

  &_sub {
    color: $grey01;
    font-size: 12px;
    font-weight: 400;

    &_small {
      @extend .description_sub;
      font-size: 10px;
    }
  }

  &_caution {
    color: $caution-red;
    font-size: 12px;
    font-weight: 700;
    &_large {
      @extend .description_caution;
      font-size: 16px;
    }

    &_sub {
      color: $sacri-orange;
      font-size: 12px;
      font-weight: 400;
    }
  }
}