@import "src/styles/variables.module";

.checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  tab-index: 0;
  -webkit-tap-highlight-color : rgba(0,0,0,0); // タップ時の影を削除

  &__tag {
    @extend .checkbox;
    background: $sacri-blue-light;
    border-radius: 16px;
    cursor: pointer;
    color: $white;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    justify-content: center;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 11px 12px;
    opacity: 0.7;
    text-align: center;

    &__input {
      display: none;
      margin: 0;
      width: 0;
      opacity: 0;
    }

    &_checked {
      @extend .checkbox__tag;
      background: $sacri-blue;
      opacity: 1;
    }
  }

  &__weekday {
    @extend .checkbox;
    background: $white;
    border: 1px solid $sacri-blue;
    border-radius: 16px;
    cursor: pointer;
    color: $sacri-blue;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    justify-content: center;
    opacity: 0.7;
    text-align: center;
    height: 32px;
    width: 32px;

    &_checked {
      @extend .checkbox__weekday;
      background: $sacri-blue;
      color: $white;
      opacity: 1;
    }
    &_disabled {
      @extend .checkbox__weekday;
      background: $grey03;
      border: 1px solid $grey03;
      color: $white;
    }

    &__holiday {
      @extend .checkbox__weekday;
      width: 40px;
      &_checked {
        @extend .checkbox__weekday__holiday;
        background: $sacri-blue;
        border: none;
        color: $white;
        opacity: 1;
      }
      &_disabled {
        @extend .checkbox__weekday__holiday;
        background: $grey03;
        border: none;
        color: $white;
      }
    }

    &__input {
      display: none;
      margin: 0;
      width: 0;
      opacity: 0;
    }
  }

  &__dummy {
    position: relative;
    top: 0;
    left: 0;
    background: $white;
    border: 1px solid $grey02;
    border-radius: 4px;
    display: block;
    min-height: 20px;
    min-width: 20px;
    width: 20px;
    height: 20px;
    transition: 150ms ease;

    &_large {
      @extend .checkbox__dummy;
      border-radius: 4px;
      min-height: 28px;
      min-width: 28px;
      height: 28px;
      width: 28px;
    }
  }

  &__input {
    display: none;
    margin: 0;
    width: 0;
    opacity: 0;

    &:checked + .checkbox__dummy {
      background: $sacri-blue;
      border: 1px solid $sacri-blue;
    }

    &:checked + .checkbox__dummy::before {
      background: $white;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 2px;
      transform: translate(-3.5px, 2px) rotateZ(-137deg);
      transform-origin: 1px 1px;
    }

    &:checked + .checkbox__dummy::after {
      background: $white;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 2px;
      transform: translate(-2.5px, 3px) rotateZ(-47deg);
      transform-origin: 1px 1px;
    }

    &:checked + .checkbox__dummy_large::before {
      background: $white;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 6px;
      height: 3px;
      transform: translate(-5px, 3px) rotateZ(-137deg);
      transform-origin: 1px 1px;
    }

    &:checked + .checkbox__dummy_large::after {
      background: $white;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 3px;
      transform: translate(-4px, 4px) rotateZ(-47deg);
      transform-origin: 1px 1px;
    }
  }

  &__label {
    margin: 0 0 0 8px;
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    user-select: none;
  }

  &__image {
    height: 44px;
    width: 44px;
    > img {
      border-radius: 8px;
      height: 44px;
      width: 44px;
      object-fit: cover;
    }
  }
}

.datetime {
  appearance: none;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  height: 100%;
  padding: 0 0 0 16px;
  width: 100%;

  &__wrapper {
    border: 1px solid $grey03;
    border-radius: 4px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    height: 48px;
    width: 100%;
    -webkit-transform: perspective(1px);
    transform: perspective(1px);

    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
    }

    // フォームのどこをタップしてもカレンダーを表示する
    input[type="date"]::-webkit-calendar-picker-indicator {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
    }
    input[type="date"]::-webkit-inner-spin-button{
      -webkit-appearance: none;
    }
    input[type="date"]::-webkit-clear-button{
      -webkit-appearance: none;
    }
  }
}

.image {
  &__list {
    display: flex;
    flex-flow: row wrap;
    &__item {
      align-items: center;
      border-radius: 4px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: auto;
      user-select: none;
      -webkit-user-select: none;
      width: calc(50% - 4px);
      &:nth-of-type(2n-1) {
        margin-right: 8px
      }
      &__box {
        border: 1px dashed $sacri-blue;
        border-radius: 4px;
        background: $white;
        position: relative;
        width: 100%;
        &::before {
          content:"";
          display: block;
          padding-top: calc(100% / 375 * 240);
        }
        &__product {
          @extend .image__list__item__box;
          &::before {
            padding-top: calc(100% / 375 * 277);
          }
        }
      }
      &__content {
        background: $grey03;
        border-radius: 4px;
        //pointer-events: none;
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        right: 2px;
        height: calc(100% - 4px);
        width: calc(100% - 4px);
      }
      &__name {
        color: $sacri-orange;
        font-weight: 700;
        text-align: left;
        width: 100%;
      }
      &__wrapper {
        width: 100%;
      }
    }

    &__icon {
      &_disabled {
        opacity: 0.7;
      }
    }
  }
}

.textarea {
  position: relative;
  width: 100%;

  &__count {
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-size: 10px;
    font-weight: 400;
  }

  &__error {
    color: $caution-red;
    font-size: 12px;
    font-weight: 700;
    padding: 4px 0;
    text-align: left;
  }

  &__input {
    background: $white;
    border: 1px solid $grey03;
    border-radius: 4px;
    font-size: 16px;
    padding: 12px;
    width: 100%;
    -webkit-appearance: none; // フォーム内に落ちる影を削除
    @include placeholder($grey03);

    &:focus {
      border: 2px solid $sacri-blue;
      outline: 0;
    }

    &__error {
      @extend .textarea__input;
      border: 2px solid $caution-red;
      color: $black;
    }
  }
}

.text {
  position: relative;
  width: 100%;

  &__error {
    color: $caution-red;
    font-size: 12px;
    font-weight: 700;
    padding: 4px 0;
    text-align: left;
  }
  
  &__icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
  }

  &__input {
    background: $white;
    border: 1px solid $grey03;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    -webkit-appearance: none; // フォーム内に落ちる影を削除
    @include placeholder($grey03);

    &:focus {
      border: 2px solid $sacri-blue;
      outline: 0;
    }

    &_filled {
      @extend .text__input;
      border: 1px solid $grey01;
      color: $black;
      font-weight: 700;
    }

    &_disabled {
      @extend .text__input;
      background: $grey04;
      color: $black;
      font-weight: 400;
    }

    &__error {
      @extend .text__input;
      border: 2px solid $caution-red;
      color: $black;
      &_filled {
        @extend .text__input__error;
        font-weight: 700;
      }
    }

    &_small {
      height: 48px;
      line-height: 48px;
      padding: 15px 16px;
    }

    &_medium {
      height: 58px;
      line-height: 56px;
      padding: 16px;
    }
  }

  &__wrapper {
    width: 100%;
  }
}

.select {
  appearance: none;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  height: 100%;
  padding: 0 16px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-ms-expand {
    display: none;
  }

  &__wrapper {
    border: 1px solid $grey03;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    height: 48px;
    width: 100%;
    -webkit-transform: perspective(1px);
    transform: perspective(1px);

    &_small {
      @extend .select__wrapper;
      width: 96px;
    }

    &_medium {
      @extend .select__wrapper;
      width: 192px;
    }
  }
}

.triangle {
  position: absolute;
  top: 12px;
  right: 12px;
  border-radius: 20%;
  background: transparent;
  height: 10px;
  margin: 0 auto;
  overflow: hidden;
  pointer-events: none;
  -webkit-transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(.866);
  transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(.866);
  width: 10px;
  &::before, &::after {
    width: 10px;
    height: 10px;
    position: absolute;
    content: "";
  }
  &::before {
    background-color: $sacri-blue;
    border-radius: 20% 20% 20% 53%;
    -webkit-transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(.866) translateX(-24%);
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(.866) translateX(-24%);
  }
  &::after {
    background-color: $sacri-blue;
    border-radius: 20% 20% 53% 20%;
    -webkit-transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(.866) translateX(24%);
    transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(.866) translateX(24%);
  }

  &_inverted {
    @extend .triangle;
    top: unset;
    bottom: 20px;
    -webkit-transform: translateY(50%) rotate(-150deg) skewY(30deg) scaleX(.866);
    transform: translateY(50%) rotate(-150deg) skewY(30deg) scaleX(.866);
  }
}

.shoppingBag {
  &__row {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-bottom: 8px;
    width: 100%;
    &__label {
      @extend .shoppingBag__row;
      padding-right: 44px;
      > p {
        display: block;
        font-size: 12px;
        width: 50%;
      }
    }
  }
}

.search {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;

  &__icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(0, -50%);
  }

  &__input {
    background: $white;
    border: 1px solid $grey03;
    border-radius: 4px 0 0 4px;
    box-sizing: border-box;
    color: $black;
    font-size: 16px;
    height: 41px;
    padding: 0 12px 0 37px;
    outline: 0;
    width: 100%;
    -webkit-appearance: none; // フォーム内に落ちる影を削除
    @include placeholder($grey03);

    &_filled {
      @extend .search__input;
      padding: 0 12px;
    }
  }

  &__button {
    background: $grey03;
    border-radius: 0 4px 4px 0;
    color: $white;
    display: block;
    font-size: 14px;
    font-weight: 700;
    height: 41px;
    padding: 0 20px;
    min-width: 68px;
    &_filled {
      @extend .search__button;
      background: $sacri-blue;
    }
  }
}

.column {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;

  &_two {
    @extend .column;
    &__item {
      margin: 0;
      max-width: 50%;
      width: 100%;
    }
  }
  &_three {
    @extend .column;
    &__item {
      margin: 0;
      max-width: 33.3333%;
      width: 100%;
    }
  }
}

.price {
  &__description {
    color: $sacri-black;
    font-size: 12px;
    font-weight: 400;
  }
  &__value {
    color: $sacri-orange;
    font-size: 18px;
    font-weight: 700;
  }
}

.radio {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  cursor: pointer;

  &__dummy {
    background: $white;
    border-radius: 50%;
    border: 1px solid $grey02;
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 28px;
    height: 28px;
  }

  &__input {
    display: none;
    &:checked + .radio__dummy::before {
      background: $sacri-blue;
      border-radius: 50%;
      border: none;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      transform: translate(-50%, -50%);
    }
  }
}

.toggle {
  &_square {
    border-radius: 4px;
    background: #fff;
    border: 1px solid $grey03;
    display: flex;
    flex-flow: row nowrap;
    height: 36px;
    width: 232px;

    &__button {
      color: $grey01;
      font-size: 12px;
      font-weight: 700;
      line-height: 34px;
      height: 34px;
      outline: 0;
      text-align: center;
      transition: .2s ease;
      width: 116px;

      &_active {
        @extend .toggle_square__button;
        background: $sacri-blue;
        border: 1px solid $sacri-blue;
        border-radius: 4px;
        color: #fff;
      }
    }
  }

  &_round {
    background: $grey03;
    border-radius: 15.5px;
    height: 26px;
    opacity: 0.7;
    position: relative;
    transition: .35s ease;
    width: 62px;
    &_active {
      @extend .toggle_round;
      background: $sacri-blue;
      opacity: 1;
    }
    &__input {
      display: none;
    }
    &__text {
      position: absolute;
      top: 3px;
      left: 28px;
      color: #fff;
      display: block;
      font-size: 12px;
      font-weight: 700;
      transition: .35s ease;
      &_active {
        @extend .toggle_round__text;
        left: 12px;
      }
    }
    &__circle {
      position: absolute;
      top: calc(50% - 20px/2);
      left: 3px;
      background: $white;
      border: 1px solid rgba(0,0,0, 0.1);
      border-radius: 50%;
      height: 20px;
      transition: .35s ease;
      width: 20px;
      &_active {
        @extend .toggle_round__circle;
        left: calc(62px - 20px - 3px);
      }
    }
  }
}

.dropzone {
  position: relative;
  outline: 0;
  &__input {
    display: none;
  }
  &_active {
    @extend .dropzone;
    &::after {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      background: rgba(0, 0, 0, .3);
      height: 100%;
      width: 100%;
      z-index: 10;
    }
  }
}