@import "src/styles/variables.module";

.required {
  background: $sacri-red;
  border-radius: 2px;
  color: $white;
  font-size: 10px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  padding: 0 4px;
}

.form {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  &__text {
    font-size: 14px;
    font-weight: 700;
  }
}

.order {
  &__filter {
    color: $grey01;
    font-size: 14px;
    font-weight: 400;
  }
}

.check {
  align-items: center;
  background: $white;
  border: 1px solid $sacri-blue;
  border-radius: 16px;
  color: $sacri-blue;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 34px;
  opacity: 0.7;
  padding: 10px 12px;
  tab-index: 0;
  -webkit-tap-highlight-color : rgba(0,0,0,0); // タップ時の影を削除

  &_checked {
    @extend .check;
    background: $sacri-blue;
    color: $white;
    opacity: 1;
  }

  &__input {
    display: none;
    margin: 0;
    width: 0;
    opacity: 0;
  }
}

.caution {
  background: $caution-red;
  color: $white;
  font-size: 18px;
  font-weight: 700;
  height: 46px;
  margin: 0 auto;
  line-height: 46px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100vw;
}