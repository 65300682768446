@import "src/styles/variables.module";

.button {
  align-items: center;
  border: none;
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  font-weight: 700;
  justify-content: center;
  letter-spacing: .1em;
  margin: 0;
  text-align: center;
  width: 100%;

  &_blue {
    &_light {
      @extend .button;
      background: $sacri-blue-light;
      color: $sacri-black;
    }
    &_main {
      @extend .button;
      background: $sacri-blue;
      color: $white;
    }
    &_dark {
      @extend .button;
      background: $sacri-blue-dark;
      color: $white;
    }
    &_outlined {
      @extend .button;
      background: $white;
      border: 1px solid $sacri-blue;
      color: $sacri-blue;
    }
  }


  &_caution {
    @extend .button;
    background: $caution-red;
    color: $white;
    &_outlined {
      @extend .button;
      background: $white;
      border: 1px solid $caution-red;
      color: $caution-red;
    }
  }

  &_orange {
    &_main {
      @extend .button;
      background: $sacri-orange;
      color: $white;
    }
  }

  &_red {
    &_main {
      @extend .button;
      background: $sacri-red;
      color: $white;
    }
  }

  &__nav {
    @extend .button;
    align-items: center;
    background: $white;
    border: 1px solid $grey03;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    text-align: center;

    &__label {
      color: $grey01;
      font-weight: 700;
    }
  }

  &_grey {
    &_outlined {
      @extend .button;
      background: $white;
      border: 1px solid $grey03;
      color: $grey01;
    }
    &_disabled {
      @extend .button;
      background: $grey03;
      color: $white;
    }
  }

  &__arrow {
    background: #fff;
    border-radius: 50%;
    display: block;
    outline: 0;
    position: relative;
    height: 16px;
    width: 16px;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 6px;
      height: 6px;
      border-top: 2px solid $sacri-blue;
      border-right: 2px solid $sacri-blue;
      transform: translate(-50%, -50%);
    }
    &_prev {
      @extend .button__arrow;
      span {
        transform: translate(-35%, -50%) rotate(-135deg);
      }
      &_disabled {
        @extend .button__arrow_prev;
        cursor: default;
        span {
          border-top: 1px solid $grey02;
          border-right: 1px solid $grey02;
        }
      }
    }
    &_next {
      @extend .button__arrow;
      span {
        transform: translate(-65%, -50%) rotate(45deg);
      }
      &_disabled {
        @extend .button__arrow_next;
        cursor: default;
        span {
          border-top: 1px solid $grey02;
          border-right: 1px solid $grey02;
        }
      }
    }
  }

  &__image {
    @extend .button;
    background: $sacri-blue;
    color: $white;
    cursor: pointer;
    font-size: 12px;
    line-height: 34px;
    height: 36px;
    max-width: 212px;
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    input {
      display: none;
    }

    &_disabled {
      @extend .button__image;
      cursor: default;
      > span {
        color: $grey03;
      }
    }
  }

  &__scroll {
    align-items: center;
    background: $sacri-orange;
    border-radius: 200px;
    color: $white;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: 4px;
    height: 38px;
    line-height: 38px;
    width: 150px;
  }

  &__text {
    align-items: center;
    color: $sacri-blue;
    cursor: pointer;
    display: flex;
    font-weight: 700;
    outline: 0;
    &_disabled {
      @extend .button__text;
      color: $grey03;
      cursor: unset;
    }
    input {
      display: none;
    }
  }

  &__close {
    outline: 0;
  }

  &__delete {
    align-items: center;
    display: flex;
    outline: 0;
    &_disabled {
      @extend .button__delete;
      opacity: 0.7;
      cursor: unset;
    }
  }

  &_xxsmall {
    font-size: 12px;
    line-height: 34px;
    height: 36px;
    max-width: 56px;
  }

  &_xsmall {
    font-size: 12px;
    line-height: 34px;
    height: 36px;
    max-width: 152px;
  }

  &_small {
    font-size: 14px;
    line-height: 48px;
    height: 48px;
    max-width: 132px;
  }

  &_medium {
    font-size: 14px;
    line-height: 48px;
    height: 48px;
    width: 100%;
  }

  &_large {
    font-size: 16px;
    line-height: 64px;
    height: 64px;
    max-width: 280px;
  }
}